import React from 'react';
import '../styles/Allsvgcomp.css';

const Allsvgcomp = ({ caseValue }) => {
    const condition = caseValue;

  let svgComponent;

  switch (condition) {
    case 'case1':
      svgComponent = (

            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="24" viewBox="0 0 68 24" fill="none">
            <path d="M31.6488 10.5418C31.4103 10.4611 31.1505 10.3838 30.8662 10.2996C30.7329 10.2609 30.5926 10.2224 30.4486 10.1873L29.8134 11.5526L27.5743 16.357H30.1608L31.9226 12.451L31.9542 12.3772L32.6139 10.9032L31.6488 10.5418ZM37.7098 6.58651L34.9196 0.585327L31.3505 8.24647L30.5433 9.98023C30.6731 9.95914 30.7995 9.93822 30.9189 9.91714C31.2101 9.86805 31.4663 9.81879 31.691 9.76625L33.3018 9.36267C33.3018 9.36267 33.6598 8.48879 34.0352 7.54473C34.453 6.50925 34.8951 5.38976 34.9407 5.21779C34.99 5.39685 35.3408 6.29182 35.6673 7.12007C35.9831 7.90614 36.2743 8.63259 36.2743 8.63259L38.4255 8.11666L37.7098 6.58651Z" fill="#653090"/>
            <path d="M42.2575 16.3535L39.1342 15.1499L38.2007 12.9949L37.0706 10.398L36.2775 8.63275L38.4288 8.11682L41.2364 14.1533L42.2575 16.3535Z" fill="#653090"/>
            <path d="M38.1907 12.9943L37.05 12.5591L35.0357 11.8117L32.6072 10.9026L31.642 10.5413C31.4033 10.4606 31.1437 10.3834 30.8594 10.299C30.7261 10.2605 30.5858 10.2219 30.4418 10.1868C30.2838 10.1447 30.119 10.1061 29.947 10.0639C30.154 10.0359 30.3506 10.0078 30.5365 9.97978C30.6663 9.95869 30.7927 9.9376 30.9121 9.91652C31.2034 9.86743 31.4595 9.81834 31.684 9.76563L33.2951 9.36204L35.2006 8.88829L36.2676 8.63214L38.4187 8.11621L37.703 6.58606L35.6604 7.11944L35.5971 7.13707L34.1233 7.51957L34.0284 7.54411L31.3437 8.24602L25.318 9.81834L29.8066 11.5519L31.9473 12.3767L39.1241 15.1493L38.1907 12.9943Z" fill="#653090"/>
            <path d="M0.234497 1.2345V3.32967H4.27048V16.4059H6.73396V3.32967H10.7418V1.2345H0.234497Z" fill="#653090"/>
            <path d="M22.5581 1.2345V6.8321H15.097V1.2345H12.6264V16.4059H15.097V9.03961H22.5581V16.4059H25.0183V1.2345H22.5581Z" fill="#653090"/>
            <path d="M45.0309 7.70228V3.35051H45.4732C46.7191 3.35051 47.5719 3.51903 48.0314 3.86298C48.4914 4.2033 48.7159 4.79286 48.7159 5.62819C48.7159 6.40373 48.5019 6.94074 48.0772 7.24598C47.6455 7.54776 46.7821 7.70228 45.4732 7.70228H45.0309ZM52.6184 15.9144L47.7684 9.50258C48.9124 9.36569 49.7757 8.95519 50.3653 8.26729C50.9514 7.57956 51.2462 6.62842 51.2462 5.41059C51.2462 4.71923 51.1199 4.08404 50.8638 3.50485C50.6076 2.92238 50.246 2.45208 49.7723 2.08359C49.3792 1.78181 48.9089 1.5642 48.3508 1.43077C47.7927 1.30097 47.0103 1.23425 46.0032 1.23425H42.5604V16.4056H44.8764V9.61838H45.1573L50.204 16.4056H52.9905L52.6184 15.9144Z" fill="#653090"/>
            <path d="M57.6414 10.4466L59.1961 6.98638C59.2943 6.74423 59.3857 6.48099 59.4805 6.19321C59.5717 5.90543 59.6595 5.59657 59.7437 5.26679C59.8455 5.61765 59.9366 5.93706 60.0384 6.22484C60.1333 6.51262 60.2244 6.76531 60.3158 6.98638L61.8668 10.4466H57.6414ZM59.7226 0.634155L52.609 15.9144L52.3773 16.4058H54.9709L56.7325 12.4998H62.7407L64.4603 16.4058H67.0645L59.7226 0.634155Z" fill="#653090"/>
            <path d="M9.54153 21.3166C9.54153 21.6453 9.47976 21.9563 9.35621 22.2494C9.23476 22.5426 9.05886 22.8002 8.82852 23.0221C8.5898 23.2504 8.32071 23.4252 8.02127 23.5467C7.72182 23.6681 7.4119 23.7289 7.09152 23.7289C6.81092 23.7289 6.53555 23.6818 6.26542 23.5875C5.99739 23.4912 5.75134 23.354 5.52728 23.1761C5.2383 22.9457 5.01633 22.6703 4.86138 22.35C4.70851 22.0296 4.63208 21.6851 4.63208 21.3166C4.63208 20.9857 4.69281 20.6758 4.81426 20.3868C4.93571 20.0957 5.11371 19.8371 5.34824 19.611C5.57858 19.3869 5.84557 19.2131 6.1492 19.0896C6.45493 18.966 6.76904 18.9042 7.09152 18.9042C7.4119 18.9042 7.72287 18.966 8.02441 19.0896C8.32804 19.2131 8.59608 19.3869 8.82852 19.611C9.06095 19.8371 9.2379 20.0957 9.35935 20.3868C9.48081 20.6779 9.54153 20.9878 9.54153 21.3166ZM7.09152 22.8525C7.50823 22.8525 7.85165 22.707 8.12178 22.4159C8.394 22.1228 8.53012 21.7563 8.53012 21.3166C8.53012 20.881 8.39296 20.5145 8.11864 20.2172C7.84432 19.9198 7.50195 19.7712 7.09152 19.7712C6.67481 19.7712 6.32929 19.9198 6.05497 20.2172C5.78066 20.5124 5.6435 20.8789 5.6435 21.3166C5.6435 21.7605 5.77856 22.128 6.04869 22.4191C6.31882 22.708 6.66643 22.8525 7.09152 22.8525Z" fill="#080605"/>
            <path d="M10.3739 23.6064V19.0079H11.3947L13.4301 21.6841C13.4888 21.7594 13.5526 21.8662 13.6217 22.0044C13.6929 22.1406 13.7662 22.3018 13.8416 22.4882C13.8186 22.3521 13.8018 22.217 13.7914 22.083C13.7809 21.949 13.7757 21.7814 13.7757 21.5804V19.0079H14.7148V23.6064H13.7505L11.6586 20.8799C11.6 20.8046 11.5361 20.6988 11.467 20.5627C11.3979 20.4245 11.3246 20.2601 11.2471 20.0696C11.2702 20.2015 11.2869 20.3355 11.2974 20.4716C11.3078 20.6056 11.3131 20.7752 11.3131 20.9805V23.6064H10.3739Z" fill="#080605"/>
            <path d="M15.7388 23.6064V19.0079H16.722V22.7709H18.2925V23.6064H15.7388Z" fill="#080605"/>
            <path d="M18.9301 23.6064V19.0079H19.9133V23.6064H18.9301Z" fill="#080605"/>
            <path d="M20.9655 23.6064V19.0079H21.9864L24.0217 21.6841C24.0804 21.7594 24.1442 21.8662 24.2134 22.0044C24.2845 22.1406 24.3578 22.3018 24.4332 22.4882C24.4102 22.3521 24.3934 22.217 24.383 22.083C24.3725 21.949 24.3673 21.7814 24.3673 21.5804V19.0079H25.3064V23.6064H24.3421L22.2502 20.8799C22.1916 20.8046 22.1277 20.6988 22.0586 20.5627C21.9895 20.4245 21.9162 20.2601 21.8387 20.0696C21.8618 20.2015 21.8785 20.3355 21.889 20.4716C21.8994 20.6056 21.9047 20.7752 21.9047 20.9805V23.6064H20.9655Z" fill="#080605"/>
            <path d="M26.3304 23.6064V19.0079H29.038V19.8371H27.3136V20.7826H29.038V21.6181H27.3136V22.7709H29.038V23.6064H26.3304Z" fill="#080605"/>
            <path d="M31.689 23.0221L32.2356 22.4034C32.3047 22.573 32.4157 22.707 32.5685 22.8054C32.7214 22.9017 32.8931 22.9499 33.0837 22.9499C33.314 22.9499 33.4962 22.8955 33.6302 22.7866C33.7642 22.6777 33.8312 22.5311 33.8312 22.3468C33.8312 22.129 33.6082 21.9123 33.1622 21.6966C33.0177 21.6275 32.9036 21.571 32.8198 21.527C32.4178 21.3176 32.1393 21.1134 31.9843 20.9145C31.8293 20.7135 31.7519 20.4748 31.7519 20.1983C31.7519 19.8193 31.9016 19.5084 32.201 19.2655C32.5026 19.0204 32.8931 18.8979 33.3726 18.8979C33.6511 18.8979 33.8909 18.9472 34.0919 19.0456C34.2951 19.1419 34.4741 19.2937 34.6291 19.501L34.048 20.057C33.9789 19.923 33.8867 19.8235 33.7715 19.7586C33.6585 19.6916 33.5213 19.6581 33.3601 19.6581C33.1653 19.6581 33.0083 19.7073 32.8889 19.8057C32.7696 19.902 32.7099 20.0277 32.7099 20.1826C32.7099 20.4255 32.9465 20.6475 33.4198 20.8485C33.514 20.8883 33.5862 20.9197 33.6365 20.9428C34.026 21.1208 34.3139 21.3218 34.5003 21.5458C34.6887 21.7699 34.783 22.0254 34.783 22.3123C34.783 22.7436 34.6353 23.0881 34.3401 23.3457C34.0469 23.6011 33.6522 23.7289 33.1559 23.7289C32.8041 23.7289 32.5026 23.6681 32.2513 23.5467C32 23.4252 31.8126 23.2504 31.689 23.0221Z" fill="#080605"/>
            <path d="M37.3084 19.8434V23.6064H36.3221V19.8434H35.1976V19.0079H38.4391V19.8434H37.3084Z" fill="#080605"/>
            <path d="M43.6721 21.3166C43.6721 21.6453 43.6104 21.9563 43.4868 22.2494C43.3654 22.5426 43.1895 22.8002 42.9591 23.0221C42.7204 23.2504 42.4513 23.4252 42.1519 23.5467C41.8524 23.6681 41.5425 23.7289 41.2221 23.7289C40.9415 23.7289 40.6661 23.6818 40.396 23.5875C40.128 23.4912 39.8819 23.354 39.6579 23.1761C39.3689 22.9457 39.1469 22.6703 38.992 22.35C38.8391 22.0296 38.7627 21.6851 38.7627 21.3166C38.7627 20.9857 38.8234 20.6758 38.9449 20.3868C39.0663 20.0957 39.2443 19.8371 39.4788 19.611C39.7092 19.3869 39.9762 19.2131 40.2798 19.0896C40.5855 18.966 40.8996 18.9042 41.2221 18.9042C41.5425 18.9042 41.8535 18.966 42.155 19.0896C42.4586 19.2131 42.7267 19.3869 42.9591 19.611C43.1915 19.8371 43.3685 20.0957 43.4899 20.3868C43.6114 20.6779 43.6721 20.9878 43.6721 21.3166ZM41.2221 22.8525C41.6388 22.8525 41.9822 22.707 42.2524 22.4159C42.5246 22.1228 42.6607 21.7563 42.6607 21.3166C42.6607 20.881 42.5235 20.5145 42.2492 20.2172C41.9749 19.9198 41.6325 19.7712 41.2221 19.7712C40.8054 19.7712 40.4599 19.9198 40.1856 20.2172C39.9112 20.5124 39.7741 20.8789 39.7741 21.3166C39.7741 21.7605 39.9092 22.128 40.1793 22.4191C40.4494 22.708 40.797 22.8525 41.2221 22.8525Z" fill="#080605"/>
            <path d="M47.8309 23.6064H46.697L45.4876 21.4579V23.6064H44.5045V19.0079H45.9117C46.4708 19.0079 46.8896 19.1168 47.1681 19.3346C47.4466 19.5502 47.5859 19.8759 47.5859 20.3114C47.5859 20.6276 47.4906 20.8977 47.3 21.1218C47.1095 21.3459 46.8634 21.4767 46.5619 21.5144L47.8309 23.6064ZM45.4876 20.9679H45.6353C46.0331 20.9679 46.2959 20.925 46.4237 20.8391C46.5514 20.7512 46.6153 20.5993 46.6153 20.3837C46.6153 20.1575 46.5462 19.9973 46.408 19.9031C46.2719 19.8068 46.0143 19.7586 45.6353 19.7586H45.4876V20.9679Z" fill="#080605"/>
            <path d="M48.4057 23.6064V19.0079H51.1133V19.8371H49.3888V20.7826H51.1133V21.6181H49.3888V22.7709H51.1133V23.6064H48.4057Z" fill="#080605"/>
          </svg>
          );
          break;
          case 'case2':
            svgComponent = (
              <svg xmlns="http://www.w3.org/2000/svg" width="159" height="21" viewBox="0 0 159 21" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M158.261 3.64944C158.261 2.01775 156.932 0.692871 155.308 0.692871H95.033C93.4085 0.692871 92.0793 2.01775 92.0793 3.64944V17.4484C92.0793 19.0801 93.4085 20.4049 95.033 20.4049H155.308C156.932 20.4049 158.261 19.0801 158.261 17.4484V3.64944Z" fill="#653090"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M158.261 3.64944C158.261 2.01775 156.932 0.692871 155.308 0.692871H95.033C93.4085 0.692871 92.0793 2.01775 92.0793 3.64944V17.4484C92.0793 19.0801 93.4085 20.4049 95.033 20.4049H155.308C156.932 20.4049 158.261 19.0801 158.261 17.4484V3.64944Z" fill="#653090"/>
      <path d="M103.226 12.7721H100.601L100.178 14.1306H98.7896L101.16 6.98914H102.701L105.072 14.1306H103.667L103.226 12.7721ZM102.871 11.6277L101.922 8.64416L100.957 11.6277H102.871Z" className='fillcolr'  fill="white"/>
      <path d="M111.994 9.14463C111.842 8.84489 111.622 8.61686 111.368 8.46003C111.097 8.30374 110.792 8.22505 110.436 8.22505C110.047 8.22505 109.708 8.32033 109.403 8.51142C109.099 8.70197 108.862 8.97442 108.692 9.32876C108.523 9.6831 108.438 10.0915 108.438 10.5545C108.438 11.0314 108.523 11.4468 108.692 11.8011C108.862 12.1555 109.116 12.4279 109.42 12.6185C109.725 12.8096 110.081 12.9048 110.487 12.9048C110.995 12.9048 111.419 12.7598 111.74 12.4702C112.062 12.1812 112.265 11.7776 112.367 11.26H110.098V10.1664H113.671V11.4131C113.586 11.9103 113.4 12.3701 113.095 12.7924C112.807 13.2148 112.434 13.5536 111.977 13.8089C111.52 14.0642 111.012 14.1921 110.453 14.1921C109.81 14.1921 109.234 14.0369 108.726 13.727C108.201 13.4171 107.812 12.9867 107.507 12.4349C107.219 11.883 107.066 11.2562 107.066 10.5545C107.066 9.85331 107.219 9.22492 107.507 8.66985C107.812 8.11479 108.201 7.68229 108.726 7.37184C109.234 7.06193 109.81 6.90723 110.436 6.90723C111.181 6.90723 111.825 7.10313 112.367 7.49494C112.926 7.88622 113.298 8.43648 113.501 9.14463H111.994Z" className='fillcolr' fill="white"/>
      <path d="M116.992 8.15385V9.94164H119.227V11.0759H116.992V12.9659H119.498V14.1306H115.671V6.98914H119.498V8.15385H116.992Z" className='fillcolr' fill="white"/>
      <path d="M127.157 14.1306H125.82L122.839 9.23668V14.1306H121.502V6.98914H122.839L125.82 11.8932V6.98914H127.157V14.1306Z" className='fillcolr' fill="white"/>
      <path d="M129.142 10.5545C129.142 9.85331 129.295 9.22492 129.583 8.66985C129.87 8.11479 130.277 7.68229 130.785 7.37184C131.293 7.06193 131.852 6.90723 132.478 6.90723C133.223 6.90723 133.867 7.11171 134.426 7.52011C134.984 7.92905 135.357 8.49428 135.577 9.21635H134.07C133.918 8.87592 133.697 8.62008 133.426 8.44987C133.155 8.27965 132.834 8.19455 132.478 8.19455C132.089 8.19455 131.75 8.29142 131.445 8.48572C131.157 8.68002 130.92 8.95408 130.751 9.30843C130.582 9.66223 130.497 10.0781 130.497 10.5545C130.497 11.025 130.582 11.4388 130.751 11.7963C130.92 12.1539 131.157 12.4295 131.445 12.6238C131.75 12.8181 132.089 12.915 132.478 12.915C132.834 12.915 133.155 12.8283 133.426 12.6543C133.697 12.4809 133.918 12.2234 134.07 11.883H135.577C135.357 12.612 134.984 13.1789 134.426 13.5841C133.884 13.9893 133.223 14.1921 132.478 14.1921C131.852 14.1921 131.293 14.0369 130.785 13.727C130.277 13.4171 129.87 12.9867 129.583 12.4349C129.295 11.883 129.142 11.2562 129.142 10.5545Z" className='fillcolr' fill="white"/>
      <path d="M138.915 6.99927H137.592V14.1309H138.915V6.99927Z" className='fillcolr' fill="white"/>
      <path d="M142.23 8.15385V9.94164H144.448V11.0759H142.23V12.9659H144.736V14.1306H140.909V6.98914H144.736V8.15385H142.23Z" className='fillcolr' fill="white"/>
      <path d="M149.196 14.2022C148.738 14.2022 148.315 14.1171 147.943 13.9469C147.587 13.7767 147.282 13.5315 147.079 13.2109C146.859 12.8908 146.757 12.5129 146.74 12.0772H148.163C148.18 12.37 148.281 12.6018 148.451 12.772C148.62 12.9422 148.857 13.0273 149.162 13.0273C149.467 13.0273 149.721 12.947 149.89 12.787C150.059 12.6269 150.161 12.4177 150.161 12.1586C150.161 11.9477 150.093 11.7738 149.975 11.6378C149.856 11.5013 149.704 11.3942 149.534 11.3161C149.348 11.2374 149.094 11.1507 148.789 11.0554C148.349 10.9189 148.01 10.7846 147.739 10.6518C147.468 10.5191 147.248 10.3178 147.045 10.0491C146.859 9.7799 146.757 9.42073 146.757 8.97111C146.757 8.54879 146.859 8.18108 147.062 7.86742C147.248 7.55429 147.519 7.31394 147.875 7.14694C148.231 6.98047 148.637 6.89697 149.094 6.89697C149.771 6.89697 150.313 7.07575 150.754 7.43331C151.177 7.79086 151.414 8.28972 151.448 8.92989H149.992C149.975 8.68474 149.89 8.48244 149.704 8.32239C149.534 8.16235 149.297 8.08205 148.993 8.08205C148.739 8.08205 148.535 8.15376 148.366 8.29668C148.214 8.43959 148.146 8.64728 148.146 8.91973C148.146 9.11082 148.197 9.26925 148.315 9.39503C148.434 9.52082 148.586 9.62305 148.755 9.70174C148.925 9.77988 149.162 9.86982 149.484 9.97206C149.907 10.1086 150.263 10.2445 150.533 10.381C150.804 10.517 151.041 10.7214 151.228 10.9939C151.431 11.2663 151.533 11.6239 151.533 12.0665C151.533 12.4482 151.431 12.8025 151.245 13.1296C151.076 13.4566 150.804 13.7167 150.449 13.911C150.093 14.1053 149.67 14.2022 149.196 14.2022Z" className='fillcolr' fill="white"/>
      <path d="M39.5874 13.0984C39.2903 12.9978 38.9666 12.9016 38.6123 12.7967C38.4463 12.7484 38.2714 12.7004 38.092 12.6567L37.3006 14.3578L34.5107 20.344H37.7335L39.9286 15.4772L39.968 15.3853L40.79 13.5487L39.5874 13.0984ZM47.1393 8.17021L43.6628 0.692871L39.2157 10.2385L38.21 12.3987C38.3718 12.3724 38.5292 12.3464 38.678 12.3201C39.0409 12.2589 39.36 12.1976 39.64 12.1321L41.6471 11.6292C41.6471 11.6292 42.0931 10.5404 42.5608 9.36414C43.0813 8.07395 43.6322 6.6791 43.6891 6.46482C43.7505 6.68792 44.1876 7.80303 44.5944 8.83501C44.9879 9.81445 45.3508 10.7196 45.3508 10.7196L48.0311 10.0768L47.1393 8.17021Z" fill="#653090"/>
      <path d="M52.8051 20.3399L48.9134 18.8402L47.7503 16.1551L46.3423 12.9194L45.3541 10.72L48.0346 10.0771L51.5328 17.5984L52.8051 20.3399Z" fill="#653090"/>
      <path d="M47.7537 16.1547L46.3324 15.6124L43.8226 14.6812L40.7967 13.5484L39.5941 13.0984C39.2967 12.9978 38.9733 12.9015 38.619 12.7964C38.453 12.7484 38.2781 12.7004 38.0987 12.6567C37.9019 12.6041 37.6964 12.5561 37.4822 12.5035C37.7401 12.4687 37.985 12.4335 38.2167 12.3987C38.3785 12.3724 38.5359 12.3461 38.6847 12.3198C39.0476 12.2587 39.3667 12.1975 39.6465 12.1318L41.6538 11.629L44.0281 11.0387L45.3574 10.7195L48.0377 10.0767L47.146 8.17017L44.6009 8.83475L44.5221 8.85672L42.6858 9.3333L42.5675 9.36388L39.2224 10.2384L31.7145 12.1975L37.3073 14.3575L39.9744 15.3852L48.9166 18.8397L47.7537 16.1547Z" fill="#653090"/>
      <path d="M0.445343 1.50208V4.11261H5.47408V20.4053H8.54352V4.11261H13.5372V1.50208H0.445343Z" fill="#653090"/>
      <path d="M28.26 1.50208V8.47656H18.9636V1.50208H15.8853V20.4053H18.9636V11.2271H28.26V20.4053H31.3253V1.50208H28.26Z" fill="#653090"/>
      <path d="M56.2606 9.56109V4.13888H56.8117C58.364 4.13888 59.4266 4.34885 59.9992 4.77741C60.5723 5.20144 60.852 5.93602 60.852 6.97683C60.852 7.94313 60.5854 8.61224 60.0563 8.99255C59.5183 9.36856 58.4426 9.56109 56.8117 9.56109H56.2606ZM65.7145 19.7933L59.6714 11.8042C61.0969 11.6337 62.1726 11.1222 62.9071 10.2651C63.6374 9.40819 64.0048 8.22309 64.0048 6.7057C64.0048 5.84428 63.8474 5.05285 63.5282 4.33119C63.2091 3.60545 62.7585 3.01947 62.1682 2.56033C61.6785 2.18432 61.0925 1.91319 60.3972 1.74693C59.7018 1.5852 58.7269 1.50208 57.472 1.50208H53.1824V20.4053H56.0681V11.9485H56.4181L62.7062 20.4053H66.1781L65.7145 19.7933Z" fill="#653090"/>
      <path d="M71.9736 12.9803L73.9107 8.66888C74.033 8.36717 74.1469 8.03918 74.265 7.68061C74.3787 7.32205 74.4881 6.93721 74.5929 6.52631C74.7198 6.96348 74.8333 7.36146 74.9601 7.72002C75.0784 8.07859 75.1918 8.39344 75.3058 8.66888L77.2384 12.9803H71.9736ZM74.5667 0.75415L65.7033 19.793L65.4146 20.4053H68.6462L70.841 15.5385H78.3272L80.4697 20.4053H83.7145L74.5667 0.75415Z" fill="#653090"/>
    </svg>
            );
            break;
            case 'case3':
          svgComponent = (
            <svg xmlns="http://www.w3.org/2000/svg" width="159" height="21" viewBox="0 0 159 21" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M158.7 3.64944C158.7 2.01775 157.371 0.692871 155.747 0.692871H95.472C93.8475 0.692871 92.5183 2.01775 92.5183 3.64944V17.4484C92.5183 19.0801 93.8475 20.4049 95.472 20.4049H155.747C157.371 20.4049 158.7 19.0801 158.7 17.4484V3.64944Z" fill="#653090"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M158.7 3.64944C158.7 2.01775 157.371 0.692871 155.747 0.692871H95.472C93.8475 0.692871 92.5183 2.01775 92.5183 3.64944V17.4484C92.5183 19.0801 93.8475 20.4049 95.472 20.4049H155.747C157.371 20.4049 158.7 19.0801 158.7 17.4484V3.64944Z" fill="#653090"/>
      <path d="M103.665 12.7721H101.04L100.617 14.1306H99.2286L101.599 6.98914H103.14L105.511 14.1306H104.106L103.665 12.7721ZM103.31 11.6277L102.361 8.64416L101.396 11.6277H103.31Z" className='fillcolr' fill="white"/>
      <path d="M112.434 9.14463C112.281 8.84489 112.061 8.61686 111.807 8.46003C111.536 8.30374 111.231 8.22505 110.875 8.22505C110.486 8.22505 110.147 8.32033 109.843 8.51142C109.538 8.70197 109.301 8.97442 109.131 9.32876C108.962 9.6831 108.877 10.0915 108.877 10.5545C108.877 11.0314 108.962 11.4468 109.131 11.8011C109.301 12.1555 109.555 12.4279 109.859 12.6185C110.164 12.8096 110.52 12.9048 110.926 12.9048C111.434 12.9048 111.858 12.7598 112.18 12.4702C112.501 12.1812 112.704 11.7776 112.806 11.26H110.537V10.1664H114.11V11.4131C114.025 11.9103 113.839 12.3701 113.534 12.7924C113.246 13.2148 112.874 13.5536 112.416 13.8089C111.959 14.0642 111.451 14.1921 110.892 14.1921C110.249 14.1921 109.673 14.0369 109.165 13.727C108.64 13.4171 108.251 12.9867 107.946 12.4349C107.658 11.883 107.506 11.2562 107.506 10.5545C107.506 9.85331 107.658 9.22492 107.946 8.66985C108.251 8.11479 108.64 7.68229 109.165 7.37184C109.673 7.06193 110.249 6.90723 110.875 6.90723C111.621 6.90723 112.264 7.10313 112.806 7.49494C113.365 7.88622 113.737 8.43648 113.94 9.14463H112.434Z" className='fillcolr' fill="white"/>
      <path d="M117.431 8.15385V9.94164H119.667V11.0759H117.431V12.9659H119.937V14.1306H116.11V6.98914H119.937V8.15385H117.431Z" className='fillcolr' fill="white"/>
      <path d="M127.596 14.1306H126.259L123.278 9.23668V14.1306H121.941V6.98914H123.278L126.259 11.8932V6.98914H127.596V14.1306Z" className='fillcolr' fill="white"/>
      <path d="M129.581 10.5545C129.581 9.85331 129.734 9.22492 130.022 8.66985C130.31 8.11479 130.716 7.68229 131.224 7.37184C131.732 7.06193 132.291 6.90723 132.917 6.90723C133.662 6.90723 134.306 7.11171 134.865 7.52011C135.423 7.92905 135.796 8.49428 136.016 9.21635H134.509C134.357 8.87592 134.136 8.62008 133.865 8.44987C133.595 8.27965 133.273 8.19455 132.917 8.19455C132.528 8.19455 132.189 8.29142 131.884 8.48572C131.596 8.68002 131.359 8.95408 131.19 9.30843C131.021 9.66223 130.936 10.0781 130.936 10.5545C130.936 11.025 131.021 11.4388 131.19 11.7963C131.359 12.1539 131.596 12.4295 131.884 12.6238C132.189 12.8181 132.528 12.915 132.917 12.915C133.273 12.915 133.595 12.8283 133.865 12.6543C134.136 12.4809 134.357 12.2234 134.509 11.883H136.016C135.796 12.612 135.423 13.1789 134.865 13.5841C134.323 13.9893 133.662 14.1921 132.917 14.1921C132.291 14.1921 131.732 14.0369 131.224 13.727C130.716 13.4171 130.31 12.9867 130.022 12.4349C129.734 11.883 129.581 11.2562 129.581 10.5545Z" className='fillcolr' fill="white"/>
      <path d="M139.354 6.99927H138.031V14.1309H139.354V6.99927Z" className='fillcolr' fill="white"/>
      <path d="M142.669 8.15385V9.94164H144.887V11.0759H142.669V12.9659H145.175V14.1306H141.348V6.98914H145.175V8.15385H142.669Z" className='fillcolr' fill="white"/>
      <path d="M149.635 14.2022C149.177 14.2022 148.754 14.1171 148.382 13.9469C148.026 13.7767 147.721 13.5315 147.518 13.2109C147.298 12.8908 147.196 12.5129 147.179 12.0772H148.602C148.619 12.37 148.721 12.6018 148.89 12.772C149.059 12.9422 149.296 13.0273 149.601 13.0273C149.906 13.0273 150.16 12.947 150.329 12.787C150.499 12.6269 150.6 12.4177 150.6 12.1586C150.6 11.9477 150.532 11.7738 150.414 11.6378C150.295 11.5013 150.143 11.3942 149.973 11.3161C149.787 11.2374 149.533 11.1507 149.229 11.0554C148.788 10.9189 148.449 10.7846 148.178 10.6518C147.908 10.5191 147.687 10.3178 147.484 10.0491C147.298 9.7799 147.197 9.42073 147.197 8.97111C147.197 8.54879 147.298 8.18108 147.501 7.86742C147.687 7.55429 147.958 7.31394 148.314 7.14694C148.67 6.98047 149.076 6.89697 149.533 6.89697C150.211 6.89697 150.753 7.07575 151.193 7.43331C151.616 7.79086 151.853 8.28972 151.887 8.92989H150.431C150.414 8.68474 150.329 8.48244 150.143 8.32239C149.973 8.16235 149.736 8.08205 149.432 8.08205C149.178 8.08205 148.975 8.15376 148.805 8.29668C148.653 8.43959 148.585 8.64728 148.585 8.91973C148.585 9.11082 148.636 9.26925 148.754 9.39503C148.873 9.52082 149.025 9.62305 149.194 9.70174C149.364 9.77988 149.601 9.86982 149.923 9.97206C150.346 10.1086 150.702 10.2445 150.973 10.381C151.243 10.517 151.48 10.7214 151.667 10.9939C151.87 11.2663 151.972 11.6239 151.972 12.0665C151.972 12.4482 151.87 12.8025 151.684 13.1296C151.515 13.4566 151.243 13.7167 150.888 13.911C150.532 14.1053 150.109 14.2022 149.635 14.2022Z" className='fillcolr' fill="white"/>
      <path d="M40.0265 13.0984C39.7293 12.9978 39.4057 12.9016 39.0514 12.7967C38.8854 12.7484 38.7105 12.7004 38.5311 12.6567L37.7397 14.3578L34.9498 20.344H38.1725L40.3676 15.4772L40.4071 15.3853L41.2291 13.5487L40.0265 13.0984ZM47.5784 8.17021L44.1019 0.692871L39.6548 10.2385L38.6491 12.3987C38.8109 12.3724 38.9683 12.3464 39.1171 12.3201C39.48 12.2589 39.7991 12.1976 40.0791 12.1321L42.0862 11.6292C42.0862 11.6292 42.5322 10.5404 42.9999 9.36414C43.5204 8.07395 44.0713 6.6791 44.1282 6.46482C44.1895 6.68792 44.6267 7.80303 45.0335 8.83501C45.427 9.81445 45.7898 10.7196 45.7898 10.7196L48.4701 10.0768L47.5784 8.17021Z" fill="#653090"/>
      <path d="M53.2442 20.3399L49.3525 18.8402L48.1894 16.1551L46.7814 12.9194L45.7932 10.72L48.4737 10.0771L51.9719 17.5984L53.2442 20.3399Z" fill="#653090"/>
      <path d="M48.1927 16.1547L46.7714 15.6124L44.2616 14.6812L41.2357 13.5484L40.0332 13.0984C39.7358 12.9978 39.4123 12.9015 39.058 12.7964C38.892 12.7484 38.7171 12.7004 38.5377 12.6567C38.3409 12.6041 38.1355 12.5561 37.9212 12.5035C38.1792 12.4687 38.424 12.4335 38.6558 12.3987C38.8175 12.3724 38.9749 12.3461 39.1237 12.3198C39.4866 12.2587 39.8057 12.1975 40.0855 12.1318L42.0928 11.629L44.4671 11.0387L45.7965 10.7195L48.4768 10.0767L47.585 8.17017L45.0399 8.83475L44.9611 8.85672L43.1248 9.3333L43.0066 9.36388L39.6615 10.2384L32.1536 12.1975L37.7463 14.3575L40.4135 15.3852L49.3556 18.8397L48.1927 16.1547Z" fill="#653090"/>
      <path d="M0.884399 1.50208V4.11261H5.91314V20.4053H8.98258V4.11261H13.9762V1.50208H0.884399Z" fill="#653090"/>
      <path d="M28.6991 1.50208V8.47656H19.4027V1.50208H16.3244V20.4053H19.4027V11.2271H28.6991V20.4053H31.7644V1.50208H28.6991Z" fill="#653090"/>
      <path d="M56.6997 9.56109V4.13888H57.2508C58.8031 4.13888 59.8657 4.34885 60.4383 4.77741C61.0113 5.20144 61.2911 5.93602 61.2911 6.97683C61.2911 7.94313 61.0245 8.61224 60.4954 8.99255C59.9574 9.36856 58.8817 9.56109 57.2508 9.56109H56.6997ZM66.1536 19.7933L60.1105 11.8042C61.5359 11.6337 62.6116 11.1222 63.3462 10.2651C64.0765 9.40819 64.4439 8.22309 64.4439 6.7057C64.4439 5.84428 64.2864 5.05285 63.9673 4.33119C63.6481 3.60545 63.1976 3.01947 62.6073 2.56033C62.1176 2.18432 61.5316 1.91319 60.8363 1.74693C60.1409 1.5852 59.166 1.50208 57.9111 1.50208H53.6215V20.4053H56.5072V11.9485H56.8572L63.1453 20.4053H66.6172L66.1536 19.7933Z" fill="#653090"/>
      <path d="M72.4127 12.9803L74.3498 8.66888C74.4721 8.36717 74.586 8.03918 74.704 7.68061C74.8178 7.32205 74.9272 6.93721 75.032 6.52631C75.1589 6.96348 75.2724 7.36146 75.3992 7.72002C75.5174 8.07859 75.6309 8.39344 75.7449 8.66888L77.6774 12.9803H72.4127ZM75.0058 0.75415L66.1424 19.793L65.8536 20.4053H69.0852L71.2801 15.5385H78.7663L80.9088 20.4053H84.1536L75.0058 0.75415Z" fill="#653090"/>
    </svg>
          );
          break;
    case 'case4':
      svgComponent = (
        <svg xmlns="http://www.w3.org/2000/svg" width="159" height="21" viewBox="0 0 159 21" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M158.399 3.64944C158.399 2.01775 157.07 0.692871 155.446 0.692871H95.1711C93.5466 0.692871 92.2174 2.01775 92.2174 3.64944V17.4484C92.2174 19.0801 93.5466 20.4049 95.1711 20.4049H155.446C157.07 20.4049 158.399 19.0801 158.399 17.4484V3.64944Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M158.399 3.64944C158.399 2.01775 157.07 0.692871 155.446 0.692871H95.1711C93.5466 0.692871 92.2174 2.01775 92.2174 3.64944V17.4484C92.2174 19.0801 93.5466 20.4049 95.1711 20.4049H155.446C157.07 20.4049 158.399 19.0801 158.399 17.4484V3.64944Z" fill="#653090"/>
  <path d="M103.364 12.7721H100.74L100.316 14.1306H98.9278L101.298 6.98914H102.839L105.21 14.1306H103.805L103.364 12.7721ZM103.009 11.6277L102.06 8.64416L101.095 11.6277H103.009Z" fill="white"/>
  <path d="M112.133 9.14463C111.98 8.84489 111.76 8.61686 111.506 8.46003C111.235 8.30374 110.93 8.22505 110.574 8.22505C110.185 8.22505 109.846 8.32033 109.542 8.51142C109.237 8.70197 109 8.97442 108.831 9.32876C108.661 9.6831 108.577 10.0915 108.577 10.5545C108.577 11.0314 108.661 11.4468 108.831 11.8011C109 12.1555 109.254 12.4279 109.558 12.6185C109.863 12.8096 110.219 12.9048 110.625 12.9048C111.133 12.9048 111.557 12.7598 111.879 12.4702C112.2 12.1812 112.403 11.7776 112.505 11.26H110.236V10.1664H113.809V11.4131C113.724 11.9103 113.538 12.3701 113.233 12.7924C112.945 13.2148 112.573 13.5536 112.115 13.8089C111.658 14.0642 111.15 14.1921 110.591 14.1921C109.948 14.1921 109.372 14.0369 108.864 13.727C108.339 13.4171 107.95 12.9867 107.645 12.4349C107.357 11.883 107.205 11.2562 107.205 10.5545C107.205 9.85331 107.357 9.22492 107.645 8.66985C107.95 8.11479 108.339 7.68229 108.864 7.37184C109.372 7.06193 109.948 6.90723 110.574 6.90723C111.32 6.90723 111.963 7.10313 112.505 7.49494C113.064 7.88622 113.436 8.43648 113.639 9.14463H112.133Z" fill="white"/>
  <path d="M117.13 8.15385V9.94164H119.366V11.0759H117.13V12.9659H119.637V14.1306H115.81V6.98914H119.637V8.15385H117.13Z" fill="white"/>
  <path d="M127.295 14.1306H125.958L122.977 9.23668V14.1306H121.64V6.98914H122.977L125.958 11.8932V6.98914H127.295V14.1306Z" fill="white"/>
  <path d="M129.281 10.5545C129.281 9.85331 129.433 9.22492 129.721 8.66985C130.009 8.11479 130.415 7.68229 130.923 7.37184C131.431 7.06193 131.99 6.90723 132.616 6.90723C133.361 6.90723 134.005 7.11171 134.564 7.52011C135.123 7.92905 135.495 8.49428 135.715 9.21635H134.208C134.056 8.87592 133.835 8.62008 133.565 8.44987C133.294 8.27965 132.972 8.19455 132.616 8.19455C132.227 8.19455 131.888 8.29142 131.583 8.48572C131.296 8.68002 131.059 8.95408 130.889 9.30843C130.72 9.66223 130.635 10.0781 130.635 10.5545C130.635 11.025 130.72 11.4388 130.889 11.7963C131.059 12.1539 131.296 12.4295 131.583 12.6238C131.888 12.8181 132.227 12.915 132.616 12.915C132.972 12.915 133.294 12.8283 133.565 12.6543C133.835 12.4809 134.056 12.2234 134.208 11.883H135.715C135.495 12.612 135.123 13.1789 134.564 13.5841C134.022 13.9893 133.361 14.1921 132.616 14.1921C131.99 14.1921 131.431 14.0369 130.923 13.727C130.415 13.4171 130.009 12.9867 129.721 12.4349C129.433 11.883 129.281 11.2562 129.281 10.5545Z" fill="white"/>
  <path d="M139.053 6.99927H137.73V14.1309H139.053V6.99927Z" fill="white"/>
  <path d="M142.368 8.15385V9.94164H144.586V11.0759H142.368V12.9659H144.874V14.1306H141.047V6.98914H144.874V8.15385H142.368Z" fill="white"/>
  <path d="M149.334 14.2022C148.877 14.2022 148.454 14.1171 148.081 13.9469C147.725 13.7767 147.42 13.5315 147.217 13.2109C146.997 12.8908 146.895 12.5129 146.878 12.0772H148.301C148.318 12.37 148.42 12.6018 148.589 12.772C148.758 12.9422 148.995 13.0273 149.3 13.0273C149.605 13.0273 149.859 12.947 150.028 12.787C150.198 12.6269 150.299 12.4177 150.299 12.1586C150.299 11.9477 150.232 11.7738 150.113 11.6378C149.994 11.5013 149.842 11.3942 149.672 11.3161C149.486 11.2374 149.232 11.1507 148.928 11.0554C148.487 10.9189 148.149 10.7846 147.878 10.6518C147.607 10.5191 147.387 10.3178 147.183 10.0491C146.997 9.7799 146.896 9.42073 146.896 8.97111C146.896 8.54879 146.997 8.18108 147.2 7.86742C147.386 7.55429 147.658 7.31394 148.013 7.14694C148.369 6.98047 148.775 6.89697 149.232 6.89697C149.91 6.89697 150.452 7.07575 150.892 7.43331C151.315 7.79086 151.552 8.28972 151.586 8.92989H150.13C150.113 8.68474 150.028 8.48244 149.842 8.32239C149.672 8.16235 149.436 8.08205 149.131 8.08205C148.877 8.08205 148.674 8.15376 148.504 8.29668C148.352 8.43959 148.284 8.64728 148.284 8.91973C148.284 9.11082 148.335 9.26925 148.453 9.39503C148.572 9.52082 148.724 9.62305 148.894 9.70174C149.063 9.77988 149.3 9.86982 149.622 9.97206C150.045 10.1086 150.401 10.2445 150.672 10.381C150.943 10.517 151.18 10.7214 151.366 10.9939C151.569 11.2663 151.671 11.6239 151.671 12.0665C151.671 12.4482 151.569 12.8025 151.383 13.1296C151.214 13.4566 150.943 13.7167 150.587 13.911C150.231 14.1053 149.808 14.2022 149.334 14.2022Z" fill="white"/>
  <path d="M39.7256 13.0984C39.4284 12.9978 39.1048 12.9016 38.7505 12.7967C38.5845 12.7484 38.4096 12.7004 38.2302 12.6567L37.4388 14.3578L34.6489 20.344H37.8716L40.0667 15.4772L40.1062 15.3853L40.9282 13.5487L39.7256 13.0984ZM47.2775 8.17021L43.801 0.692871L39.3539 10.2385L38.3482 12.3987C38.51 12.3724 38.6674 12.3464 38.8162 12.3201C39.1791 12.2589 39.4982 12.1976 39.7782 12.1321L41.7853 11.6292C41.7853 11.6292 42.2313 10.5404 42.699 9.36414C43.2195 8.07395 43.7704 6.6791 43.8273 6.46482C43.8886 6.68792 44.3258 7.80303 44.7326 8.83501C45.1261 9.81445 45.4889 10.7196 45.4889 10.7196L48.1692 10.0768L47.2775 8.17021Z" fill="#653090"/>
  <path d="M52.9433 20.3399L49.0516 18.8402L47.8885 16.1551L46.4805 12.9194L45.4922 10.72L48.1727 10.0771L51.6709 17.5984L52.9433 20.3399Z" fill="#653090"/>
  <path d="M47.8918 16.1547L46.4705 15.6124L43.9608 14.6812L40.9348 13.5484L39.7323 13.0984C39.4349 12.9978 39.1114 12.9015 38.7572 12.7964C38.5911 12.7484 38.4163 12.7004 38.2369 12.6567C38.04 12.6041 37.8346 12.5561 37.6203 12.5035C37.8783 12.4687 38.1232 12.4335 38.3549 12.3987C38.5166 12.3724 38.674 12.3461 38.8228 12.3198C39.1857 12.2587 39.5049 12.1975 39.7846 12.1318L41.7919 11.629L44.1662 11.0387L45.4956 10.7195L48.1759 10.0767L47.2841 8.17017L44.7391 8.83475L44.6602 8.85672L42.8239 9.3333L42.7057 9.36388L39.3606 10.2384L31.8527 12.1975L37.4454 14.3575L40.1126 15.3852L49.0548 18.8397L47.8918 16.1547Z" fill="#653090"/>
  <path d="M0.583496 1.50208V4.11261H5.61223V20.4053H8.68168V4.11261H13.6753V1.50208H0.583496Z" fill="#653090"/>
  <path d="M28.3982 1.50208V8.47656H19.1018V1.50208H16.0235V20.4053H19.1018V11.2271H28.3982V20.4053H31.4635V1.50208H28.3982Z" fill="#653090"/>
  <path d="M56.3988 9.56109V4.13888H56.9499C58.5022 4.13888 59.5648 4.34885 60.1374 4.77741C60.7104 5.20144 60.9902 5.93602 60.9902 6.97683C60.9902 7.94313 60.7236 8.61224 60.1944 8.99255C59.6565 9.36856 58.5808 9.56109 56.9499 9.56109H56.3988ZM65.8527 19.7933L59.8096 11.8042C61.235 11.6337 62.3107 11.1222 63.0453 10.2651C63.7756 9.40819 64.143 8.22309 64.143 6.7057C64.143 5.84428 63.9855 5.05285 63.6664 4.33119C63.3472 3.60545 62.8967 3.01947 62.3064 2.56033C61.8167 2.18432 61.2307 1.91319 60.5354 1.74693C59.84 1.5852 58.8651 1.50208 57.6102 1.50208H53.3206V20.4053H56.2063V11.9485H56.5563L62.8444 20.4053H66.3163L65.8527 19.7933Z" fill="#653090"/>
  <path d="M72.1118 12.9803L74.0489 8.66888C74.1712 8.36717 74.2851 8.03918 74.4031 7.68061C74.5169 7.32205 74.6263 6.93721 74.7311 6.52631C74.858 6.96348 74.9715 7.36146 75.0983 7.72002C75.2165 8.07859 75.33 8.39344 75.444 8.66888L77.3765 12.9803H72.1118ZM74.7049 0.75415L65.8415 19.793L65.5527 20.4053H68.7843L70.9792 15.5385H78.4654L80.6079 20.4053H83.8527L74.7049 0.75415Z" fill="#653090"/>
</svg>
      );
      break;

    case 'case5':
      svgComponent = (
        <svg xmlns="http://www.w3.org/2000/svg" width="104" height="21" viewBox="0 0 104 21" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M38.842 13.0098C38.5506 12.9112 38.2332 12.8168 37.8859 12.7139C37.7231 12.6666 37.5516 12.6196 37.3757 12.5767L36.5996 14.2447L33.864 20.1145H37.0241L39.1765 15.3423L39.2152 15.2522L40.0212 13.4513L38.842 13.0098ZM46.2471 8.17742L42.8382 0.845459L38.4776 10.2055L37.4914 12.3237C37.65 12.2979 37.8043 12.2724 37.9503 12.2466C38.3061 12.1867 38.619 12.1265 38.8936 12.0623L40.8616 11.5692C40.8616 11.5692 41.299 10.5015 41.7576 9.34814C42.268 8.08303 42.8082 6.71529 42.8639 6.50518C42.9241 6.72395 43.3528 7.81738 43.7517 8.8293C44.1375 9.78969 44.4933 10.6772 44.4933 10.6772L47.1215 10.0469L46.2471 8.17742Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M51.8027 20.1103L47.9867 18.6398L46.8462 16.0069L45.4656 12.8341L44.4965 10.6775L47.1249 10.0471L50.5551 17.4222L51.8027 20.1103Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M46.8346 16.0064L45.4409 15.4747L42.98 14.5616L40.0128 13.4508L38.8337 13.0095C38.542 12.9109 38.2249 12.8165 37.8775 12.7134C37.7147 12.6663 37.5432 12.6193 37.3673 12.5764C37.1743 12.5249 36.9729 12.4778 36.7627 12.4262C37.0157 12.392 37.2558 12.3576 37.483 12.3234C37.6416 12.2976 37.796 12.2719 37.9419 12.2461C38.2977 12.1861 38.6107 12.1262 38.885 12.0618L40.8533 11.5687L43.1814 10.9899L44.4849 10.6769L47.1131 10.0466L46.2387 8.17712L43.7431 8.82879L43.6658 8.85033L41.8652 9.31764L41.7493 9.34763L38.4692 10.2052L31.1072 12.1262L36.5913 14.2442L39.2066 15.2519L47.9749 18.6392L46.8346 16.0064Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M0.46106 1.63855V4.19833H5.39205V20.1742H8.40183V4.19833H13.2984V1.63855H0.46106Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.735 1.63855V8.47744H18.6193V1.63855H15.6009V20.1742H18.6193V11.1745H27.735V20.1742H30.7408V1.63855H27.735Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M55.1914 9.54039V4.2236H55.7318C57.2539 4.2236 58.2958 4.42949 58.8573 4.84971C59.4192 5.2655 59.6935 5.9858 59.6935 7.00637C59.6935 7.95388 59.4321 8.60998 58.9132 8.98291C58.3858 9.3516 57.331 9.54039 55.7318 9.54039H55.1914ZM64.4615 19.5736L58.5359 11.7399C59.9336 11.5727 60.9884 11.0711 61.7087 10.2307C62.4248 9.39046 62.785 8.2284 62.785 6.74051C62.785 5.89584 62.6307 5.1198 62.3177 4.41218C62.0048 3.70054 61.563 3.12595 60.9842 2.67574C60.504 2.30704 59.9294 2.04118 59.2475 1.87816C58.5657 1.71957 57.6097 1.63806 56.3792 1.63806H52.173V20.1738H55.0026V11.8814H55.3458L61.5117 20.1738H64.9161L64.4615 19.5736Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M70.5984 12.8935L72.4978 8.66587C72.6178 8.37003 72.7295 8.04842 72.8452 7.69682C72.9567 7.34523 73.064 6.96787 73.1668 6.56496C73.2912 6.99363 73.4025 7.38387 73.5268 7.73547C73.6428 8.08706 73.7541 8.39579 73.8658 8.66587L75.7608 12.8935H70.5984ZM73.141 0.905029L64.45 19.5738L64.1668 20.1741H67.3356L69.4878 15.4019H76.8284L78.9294 20.1741H82.111L73.141 0.905029Z" fill="#653090"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M89.2279 3.59716L89.4806 3.44005C89.6556 3.32882 89.7869 3.19949 89.8744 3.0521C89.9619 2.9061 90.0056 2.74202 90.0056 2.55986C90.0056 2.3499 89.9425 2.17884 89.8258 2.04536C89.7092 1.91187 89.5536 1.84513 89.3641 1.84513C89.155 1.84513 88.9849 1.91326 88.8585 2.04814C88.7273 2.18302 88.6641 2.35683 88.6641 2.56957C88.6641 2.67386 88.6835 2.77539 88.7175 2.87272C88.7564 2.97145 88.8099 3.06322 88.8877 3.15082L89.2279 3.59716ZM90.4577 6.83005L88.9606 4.96267L88.7856 5.08915C88.4599 5.31859 88.2315 5.52859 88.1003 5.71908C87.9739 5.90958 87.9058 6.12091 87.9058 6.35451C87.9058 6.68128 88.0127 6.95801 88.2266 7.18466C88.4356 7.40992 88.7029 7.52251 89.014 7.52251C89.2522 7.52251 89.4855 7.4655 89.7237 7.35148C89.9618 7.23607 90.2098 7.06226 90.4577 6.83005ZM91.6437 8.32204L91.0896 7.61987C90.7639 7.92022 90.4333 8.14127 90.0882 8.28449C89.748 8.42771 89.3737 8.50006 88.9703 8.50006C88.3384 8.50006 87.828 8.30258 87.4343 7.90768C87.0357 7.51278 86.8413 7.00529 86.8413 6.38513C86.8413 5.96242 86.9628 5.57446 87.2059 5.21849C87.4489 4.86253 87.8378 4.50515 88.3676 4.14779L88.0905 3.8238C87.9349 3.64581 87.8183 3.44979 87.7405 3.23705C87.6579 3.0243 87.619 2.80879 87.619 2.59049C87.619 2.10381 87.7794 1.7047 88.1099 1.39323C88.4405 1.08037 88.8633 0.924683 89.3834 0.924683C89.8987 0.924683 90.307 1.06652 90.6084 1.3474C90.9097 1.62967 91.0604 2.00926 91.0604 2.48898C91.0604 2.84912 90.9534 3.17865 90.7444 3.4776C90.5306 3.77656 90.1904 4.07275 89.7286 4.36614L91.1576 6.15707C91.2937 6.00829 91.4347 5.84002 91.5757 5.65092C91.7215 5.46181 91.8722 5.2449 92.0375 4.99879L92.6887 5.75242C92.5672 5.96795 92.4262 6.17234 92.2756 6.36701C92.12 6.56028 91.9548 6.74246 91.7798 6.91071L92.9269 8.32204H91.6437Z" fill="#080605"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M92.8396 14.3374C92.5237 14.0468 92.1931 13.8299 91.8432 13.6839C91.4883 13.5393 91.1189 13.4669 90.7349 13.4669C89.9863 13.4669 89.3739 13.727 88.9073 14.2456C88.4358 14.7657 88.2024 15.4387 88.2024 16.2646C88.2024 17.0642 88.4309 17.7246 88.8878 18.2433C89.3447 18.7633 89.9231 19.0233 90.6182 19.0233C91.0216 19.0233 91.4105 18.9455 91.7751 18.7898C92.1445 18.6354 92.4944 18.4004 92.8298 18.0862V19.4475C92.5333 19.6811 92.2077 19.8549 91.8528 19.9689C91.498 20.0829 91.1189 20.1413 90.7057 20.1413C90.1808 20.1413 89.6995 20.0481 89.2523 19.8632C88.81 19.6769 88.4163 19.4043 88.0809 19.0428C87.7455 18.6882 87.4879 18.2711 87.3129 17.79C87.133 17.3103 87.0407 16.7986 87.0407 16.2549C87.0407 15.7112 87.133 15.2009 87.3129 14.724C87.4879 14.247 87.7504 13.8271 88.0906 13.4669C88.4309 13.1026 88.8197 12.8274 89.262 12.6396C89.6995 12.4519 90.1759 12.3588 90.6863 12.3588C91.0897 12.3588 91.4737 12.4213 91.8382 12.5479C92.2028 12.6744 92.5576 12.8663 92.9027 13.1221L92.8396 14.3374Z" fill="#080605"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M100.087 16.2549C100.087 15.8808 100.024 15.5248 99.8972 15.1869C99.7708 14.8504 99.5862 14.5501 99.3528 14.2859C99.1195 14.0273 98.8521 13.827 98.5459 13.6866C98.2445 13.5475 97.9237 13.4767 97.5884 13.4767C97.2481 13.4767 96.9273 13.5461 96.6259 13.6838C96.3197 13.8229 96.0475 14.0231 95.8142 14.2859C95.576 14.5459 95.3962 14.8449 95.2698 15.1814C95.1434 15.5193 95.0802 15.8766 95.0802 16.2549C95.0802 16.6289 95.1434 16.9834 95.2698 17.3171C95.3962 17.6509 95.576 17.9498 95.8142 18.2126C96.0475 18.4768 96.3197 18.677 96.6211 18.8147C96.9224 18.9537 97.2481 19.0233 97.5884 19.0233C97.9189 19.0233 98.2397 18.9537 98.5362 18.8147C98.8376 18.677 99.1098 18.4768 99.3528 18.2126C99.5862 17.9498 99.7708 17.6508 99.8972 17.3144C100.024 16.9792 100.087 16.6261 100.087 16.2549ZM101.249 16.2549C101.249 16.7805 101.156 17.2782 100.971 17.7468C100.792 18.2168 100.524 18.6353 100.174 19.0024C99.8194 19.3695 99.4257 19.6518 98.9785 19.8479C98.5362 20.0425 98.0744 20.1412 97.5884 20.1412C97.0926 20.1412 96.6211 20.0425 96.1739 19.8451C95.7267 19.6476 95.333 19.3667 94.9879 19.0024C94.6379 18.6353 94.3754 18.2182 94.1907 17.7524C94.0108 17.2866 93.9233 16.7874 93.9233 16.2549C93.9233 15.7251 94.0108 15.2259 94.1907 14.7573C94.3754 14.2887 94.6379 13.8688 94.9879 13.4975C95.3378 13.129 95.7364 12.8495 96.1787 12.6576C96.6211 12.4644 97.0877 12.3684 97.5884 12.3684C98.0793 12.3684 98.546 12.4644 98.9883 12.6576C99.4306 12.8495 99.8243 13.129 100.174 13.4975C100.524 13.8715 100.792 14.2942 100.971 14.7642C101.156 15.2356 101.249 15.732 101.249 16.2549Z" fill="#080605"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M102.57 19.3821C102.57 19.1958 102.629 19.0373 102.75 18.9065C102.867 18.7744 103.013 18.7091 103.183 18.7091C103.353 18.7091 103.499 18.7744 103.62 18.9065C103.737 19.0373 103.795 19.1958 103.795 19.3821C103.795 19.5642 103.737 19.7186 103.62 19.8451C103.499 19.9717 103.353 20.0342 103.183 20.0342C103.013 20.0342 102.867 19.9717 102.75 19.8451C102.629 19.7186 102.57 19.5642 102.57 19.3821Z" fill="#080605"/>
</svg>
      );
      break;
      case 'case6':
        svgComponent = ( 
          <svg className='productsvg' viewBox="0 0 564 526" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M428.743 413.79L390.759 399.298L323.688 374.413L242.822 344.14L210.685 332.111C202.737 329.424 194.092 326.851 184.625 324.043C180.188 322.759 175.515 321.476 170.721 320.307C165.46 318.903 159.97 317.62 154.243 316.215C161.138 315.283 167.682 314.345 173.874 313.413C178.197 312.711 182.404 312.008 186.38 311.306C196.078 309.672 204.607 308.037 212.083 306.282L265.727 292.843L329.178 277.068L364.705 268.539L436.335 251.36L412.502 200.408L344.487 218.169L342.381 218.756L293.306 231.492L290.147 232.31L200.751 255.682L0.107666 308.037L149.57 365.762L220.848 393.227L459.821 485.547L428.743 413.79Z" fill="#DAADFF"/>
<path d="M563.742 525.636L459.74 485.557L428.656 413.8L391.029 327.328L364.618 268.549L436.253 251.37L529.741 452.372L563.742 525.636Z" fill="#DAADFF"/>
<path d="M210.506 332.112C202.563 329.424 193.913 326.852 184.446 324.049C180.009 322.76 175.336 321.476 170.541 320.308L149.391 365.769L74.8325 525.747H160.959L219.622 395.685L220.675 393.227L242.643 344.146L210.506 332.112ZM412.323 200.409L319.417 0.580555L200.572 255.682L173.695 313.413C178.018 312.711 182.225 312.015 186.201 311.312C195.899 309.678 204.428 308.038 211.91 306.288L265.548 292.85C265.548 292.85 277.467 263.751 289.968 232.316C303.878 197.836 318.6 160.559 320.119 154.833C321.759 160.795 333.442 190.596 344.314 218.175C354.829 244.35 364.526 268.539 364.526 268.539L436.155 251.36L412.323 200.409Z" fill="#DAADFF"/>
</svg>

        );
        break;
        case 'case7':
        svgComponent =(
          <svg xmlns="http://www.w3.org/2000/svg" className="underlinne"width="304" height="30" viewBox="0 0 304 30" fill="none">
  <path d="M0.482644 3.96214C90.5021 -2.48362 269.679 7.27538 302.279 5.39229C306.748 5.13413 80.5022 8.93468 70.3381 28.9528" stroke="#450079" strokeWidth="3"/>
</svg>
        );
        break;
        case 'case8':
          svgComponent =(
            <svg xmlns="http://www.w3.org/2000/svg" className="underlinne2" width="257" height="33" viewBox="0 0 257 33" fill="none">
            <path d="M0.194518 7.23916C90.214 0.793396 223.33 3.59807 255.93 1.71499C260.399 1.45683 80.2141 12.2117 70.0499 32.2298" stroke="#450079" strokeWidth="3"/>
          </svg>
          );
          break;
          case 'case9':
            svgComponent =(
              <svg xmlns="http://www.w3.org/2000/svg" width="734" className="underlinne3" height="46" viewBox="0 0 734 46" fill="none">
              <path d="M733.744 18.1005C516.293 -0.298791 81.3385 8.35322 2.54765 2.59439C-8.25381 1.80491 307.225 4.00834 329.954 44.7491" stroke="#450079" strokeWidth="3"/>
            </svg>
            );
            break;
    // Add more cases as needed
    case 'case10':
      svgComponent =(
<svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='42'
                      height='42'
                      viewBox='0 0 42 42'
                      fill='none'
                    >
                      <path
                        d='M4.8829 29.0784L14.0669 20.2476L21.9389 27.8169L37.6829 12.6784M37.6829 12.6784H25.8749M37.6829 12.6784V24.0322'
                        stroke='black'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
      );
      break;
case 'case11':
      svgComponent =(
         <svg  className='Ellipse-img'xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
                   <circle cx="30.3408" cy="30.6431" r="29.665" fill="#EFEFEF"/>
                  </svg> 
                   );
      break;
      case 'case12':
      svgComponent =(
        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
          <g clipPath="url(#clip0_160_109439)">
        <path d="M24.3707 14.7233C25.8712 14.0941 26.5004 12.3032 25.8228 10.8511L23.4511 5.86567C22.7735 4.46248 21.1278 3.83277 19.6757 4.46248C15.5615 6.20497 12.0281 8.47988 9.2208 11.2388C5.78423 14.4813 3.46091 18.2083 2.20245 22.3225C0.943991 26.4855 0.266357 32.1481 0.266357 39.3601V54.2196C0.266357 55.8169 1.57322 57.1237 3.1705 57.1237H22.1926C23.7899 57.1237 25.0968 55.8169 25.0968 54.2196V35.1975C25.0968 33.5997 23.7899 32.2933 22.1926 32.2933H13.093C13.1898 27.4042 14.3514 23.4841 16.4812 20.5311C18.2236 18.1603 20.8374 16.2242 24.3707 14.7233Z" fill="#450079"/>
      <path d="M58.5428 14.7233C60.0433 14.094 60.672 12.3031 59.9949 10.8511L57.6231 5.91452C56.9455 4.51085 55.2998 3.88162 53.8478 4.51085C49.782 6.25334 46.2965 8.52824 43.4413 11.2388C40.0042 14.5301 37.633 18.2571 36.374 22.3713C35.1156 26.4366 34.4868 32.1002 34.4868 39.3605V54.22C34.4868 55.8173 35.7937 57.1242 37.391 57.1242H56.4131C58.0104 57.1242 59.3172 55.8173 59.3172 54.22V35.1979C59.3172 33.6002 58.0104 32.2938 56.4131 32.2938H47.265C47.3618 27.4047 48.524 23.4846 50.6532 20.5315C52.3957 18.1603 55.0094 16.2242 58.5428 14.7233Z" fill="#450079"/>
      </g>
        <defs>
          <clipPath id="clip0_160_109439">
      <rect width="60.0001" height="60" fill="white" transform="translate(0.266357 0.671143)"/>
      </clipPath>
         </defs>
        </svg> 
      );
      break;
      case 'case13':
        svgComponent =(<><svg width="101.383" height="46" viewBox="0 0 1016 426" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1015.38 296.303H514.507L457.139 425.072H1015.38V296.303ZM1000.05 311.633V409.742H480.751L524.456 311.633H1000.05Z" fill="#653090"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M527.547 296.303H0.86193V425.072H471.661L527.547 296.303Z" fill="#653090"/>
          <path d="M139.047 346.186C137.186 342.596 134.602 339.888 131.294 338.062C127.977 336.228 124.15 335.319 119.801 335.319C115.047 335.319 110.804 336.386 107.073 338.521C103.351 340.664 100.431 343.698 98.3321 347.632C96.224 351.566 95.1744 356.118 95.1744 361.286C95.1744 366.464 96.224 371.033 98.3321 375.002C100.431 378.971 103.351 382.023 107.073 384.158C110.804 386.301 115.047 387.368 119.801 387.368C126.222 387.368 131.426 385.569 135.431 381.988C139.426 378.398 141.878 373.538 142.778 367.399H115.77V357.979H155.4V367.187C154.642 372.78 152.657 377.913 149.446 382.605C146.245 387.298 142.073 391.038 136.93 393.834C131.788 396.63 126.081 398.024 119.801 398.024C113.045 398.024 106.87 396.453 101.278 393.313C95.6948 390.182 91.2581 385.816 87.9857 380.224C84.7045 374.641 83.064 368.325 83.064 361.286C83.064 354.257 84.7045 347.941 87.9857 342.349C91.2581 336.766 95.7124 332.4 101.331 329.259C106.958 326.119 113.115 324.558 119.801 324.558C127.457 324.558 134.275 326.437 140.238 330.194C146.209 333.952 150.54 339.279 153.23 346.186H139.047Z" fill="white"/>
          <path d="M204.345 397.402L187.789 368.63H178.783V397.402H166.99V325.48H191.828C197.341 325.48 202.016 326.451 205.844 328.382C209.672 330.314 212.539 332.898 214.435 336.144C216.332 339.381 217.284 343.007 217.284 347.002C217.284 351.695 215.917 355.955 213.191 359.783C210.466 363.611 206.276 366.222 200.622 367.598L218.422 397.402H204.345ZM178.783 359.219H191.828C196.239 359.219 199.573 358.116 201.813 355.902C204.054 353.697 205.174 350.733 205.174 347.002C205.174 343.28 204.071 340.369 201.866 338.261C199.652 336.153 196.309 335.103 191.828 335.103H178.783V359.219Z" fill="white"/>
          <path d="M265.198 398.121C258.503 398.121 252.347 396.551 246.719 393.41C241.101 390.27 236.655 385.896 233.374 380.268C230.101 374.649 228.461 368.316 228.461 361.278C228.461 354.248 230.101 347.932 233.374 342.34C236.655 336.757 241.101 332.391 246.719 329.251C252.347 326.11 258.503 324.549 265.198 324.549C271.954 324.549 278.146 326.11 283.774 329.251C289.392 332.391 293.82 336.757 297.066 342.34C300.312 347.932 301.926 354.248 301.926 361.278C301.926 368.316 300.312 374.649 297.066 380.268C293.82 385.896 289.392 390.27 283.774 393.41C278.146 396.551 271.954 398.121 265.198 398.121ZM265.198 387.871C269.952 387.871 274.195 386.786 277.926 384.617C281.648 382.447 284.559 379.342 286.667 375.302C288.775 371.271 289.825 366.596 289.825 361.278C289.825 355.968 288.775 351.311 286.667 347.315C284.559 343.31 281.648 340.241 277.926 338.098C274.195 335.963 269.952 334.896 265.198 334.896C260.435 334.896 256.192 335.963 252.47 338.098C248.739 340.241 245.828 343.31 243.72 347.315C241.621 351.311 240.563 355.968 240.563 361.278C240.563 366.596 241.621 371.271 243.72 375.302C245.828 379.342 248.739 382.447 252.47 384.617C256.192 386.786 260.435 387.871 265.198 387.871Z" fill="white"/>
          <path d="M325.211 325.476V371.316C325.211 376.767 326.64 380.877 329.507 383.629C332.373 386.39 336.351 387.775 341.458 387.775C346.636 387.775 350.649 386.39 353.516 383.629C356.373 380.877 357.811 376.767 357.811 371.316V325.476H369.604V371.113C369.604 376.979 368.325 381.945 365.776 386.011C363.227 390.086 359.813 393.12 355.535 395.122C351.258 397.125 346.53 398.121 341.352 398.121C336.184 398.121 331.473 397.125 327.231 395.122C322.988 393.12 319.628 390.086 317.14 386.011C314.653 381.945 313.418 376.979 313.418 371.113V325.476H325.211Z" fill="white"/>
          <path d="M435.21 346.905C435.21 350.557 434.346 354.006 432.626 357.252C430.897 360.489 428.136 363.135 424.343 365.164C420.55 367.201 415.69 368.215 409.754 368.215H396.718V397.402H384.916V325.48H409.754C415.276 325.48 419.951 326.433 423.779 328.329C427.607 330.226 430.465 332.793 432.37 336.039C434.266 339.276 435.21 342.901 435.21 346.905ZM409.754 358.592C414.235 358.592 417.587 357.578 419.792 355.54C421.997 353.512 423.108 350.627 423.108 346.905C423.108 339.037 418.654 335.103 409.754 335.103H396.718V358.592H409.754Z" fill="white"/>
          <path d="M573.207 391.789C569.458 391.789 566.071 391.128 563.072 389.814C560.065 388.499 557.701 386.638 555.99 384.222C554.27 381.805 553.414 378.991 553.414 375.772H563.231C563.443 378.18 564.395 380.164 566.089 381.725C567.783 383.278 570.155 384.063 573.207 384.063C576.374 384.063 578.843 383.295 580.616 381.77C582.389 380.235 583.271 378.268 583.271 375.851C583.271 373.972 582.724 372.437 581.622 371.264C580.519 370.082 579.152 369.165 577.52 368.521C575.88 367.877 573.613 367.18 570.72 366.431C567.068 365.469 564.104 364.49 561.82 363.493C559.544 362.506 557.595 360.962 555.99 358.872C554.375 356.772 553.573 353.985 553.573 350.501C553.573 347.282 554.375 344.459 555.99 342.042C557.595 339.634 559.853 337.782 562.746 336.494C565.648 335.206 569 334.562 572.81 334.562C578.226 334.562 582.671 335.912 586.129 338.629C589.587 341.337 591.509 345.05 591.88 349.769H581.745C581.578 347.731 580.616 345.994 578.843 344.538C577.07 343.092 574.742 342.369 571.84 342.369C569.211 342.369 567.068 343.039 565.401 344.38C563.743 345.72 562.905 347.652 562.905 350.175C562.905 351.895 563.434 353.297 564.475 354.4C565.524 355.502 566.856 356.375 568.462 357.019C570.076 357.663 572.272 358.36 575.059 359.11C578.764 360.124 581.78 361.147 584.118 362.17C586.446 363.185 588.44 364.755 590.072 366.872C591.712 368.998 592.524 371.829 592.524 375.366C592.524 378.206 591.765 380.896 590.23 383.419C588.705 385.933 586.473 387.961 583.553 389.496C580.634 391.022 577.185 391.789 573.207 391.789Z" fill="#653090"/>
          <path d="M608.065 341.011C606.398 341.011 605.004 340.446 603.875 339.317C602.746 338.188 602.182 336.794 602.182 335.136C602.182 333.469 602.746 332.075 603.875 330.946C605.004 329.817 606.398 329.262 608.065 329.262C609.67 329.262 611.037 329.817 612.166 330.946C613.295 332.075 613.86 333.469 613.86 335.136C613.86 336.794 613.295 338.188 612.166 339.317C611.037 340.446 609.67 341.011 608.065 341.011ZM612.572 346.885V391.234H603.39V346.885H612.572Z" fill="#653090"/>
          <path d="M647.016 346.152C650.5 346.152 653.631 346.876 656.392 348.322C659.153 349.769 661.314 351.921 662.875 354.761C664.428 357.61 665.204 361.041 665.204 365.063V391.225H656.11V366.431C656.11 362.461 655.122 359.418 653.129 357.301C651.144 355.176 648.436 354.117 645.005 354.117C641.565 354.117 638.848 355.176 636.837 357.301C634.826 359.418 633.812 362.461 633.812 366.431V391.225H624.639V346.876H633.812V351.947C635.32 350.121 637.234 348.701 639.572 347.678C641.909 346.664 644.388 346.152 647.016 346.152Z" fill="#653090"/>
          <path d="M673.812 369.002C673.812 364.442 674.739 360.429 676.591 356.971C678.443 353.514 681.01 350.841 684.282 348.962C687.555 347.083 691.312 346.148 695.546 346.148C700.918 346.148 705.354 347.419 708.865 349.968C712.384 352.517 714.757 356.151 715.992 360.879H706.095C705.284 358.674 703.996 356.962 702.232 355.727C700.459 354.493 698.227 353.875 695.546 353.875C691.789 353.875 688.798 355.198 686.576 357.853C684.344 360.517 683.233 364.23 683.233 369.002C683.233 373.783 684.344 377.505 686.576 380.195C688.798 382.877 691.789 384.217 695.546 384.217C700.856 384.217 704.375 381.88 706.095 377.214H715.992C714.704 381.721 712.287 385.302 708.75 387.957C705.204 390.612 700.803 391.944 695.546 391.944C691.312 391.944 687.555 390.992 684.282 389.086C681.01 387.181 678.443 384.5 676.591 381.033C674.739 377.576 673.812 373.562 673.812 369.002Z" fill="#653090"/>
          <path d="M765.809 367.961C765.809 369.62 765.703 371.119 765.491 372.469H731.603C731.868 376.006 733.191 378.846 735.546 380.998C737.91 383.141 740.803 384.217 744.243 384.217C749.173 384.217 752.666 382.153 754.704 378.017H764.6C763.26 382.092 760.834 385.435 757.323 388.037C753.804 390.639 749.447 391.944 744.243 391.944C740 391.944 736.207 390.992 732.856 389.086C729.495 387.181 726.867 384.5 724.961 381.033C723.056 377.576 722.104 373.562 722.104 369.002C722.104 364.442 723.03 360.429 724.882 356.971C726.734 353.514 729.336 350.841 732.688 348.962C736.049 347.083 739.894 346.148 744.243 346.148C748.424 346.148 752.155 347.057 755.427 348.883C758.699 350.709 761.248 353.267 763.074 356.565C764.9 359.873 765.809 363.666 765.809 367.961ZM756.23 365.06C756.177 361.681 754.968 358.973 752.613 356.936C750.249 354.889 747.321 353.875 743.837 353.875C740.671 353.875 737.963 354.881 735.705 356.892C733.455 358.903 732.115 361.628 731.682 365.06H756.23Z" fill="#653090"/>
          <path d="M793.338 340.924V332.554H810.079V391.227H800.818V340.924H793.338Z" fill="#653090"/>
          <path d="M834.141 375.283C834.679 377.964 835.835 380.002 837.608 381.395C839.372 382.798 841.762 383.495 844.77 383.495C848.571 383.495 851.367 381.986 853.14 378.987C854.904 375.98 855.795 370.855 855.795 363.613C854.508 365.439 852.691 366.842 850.362 367.838C848.025 368.826 845.493 369.329 842.759 369.329C839.266 369.329 836.099 368.615 833.259 367.194C830.41 365.774 828.17 363.649 826.538 360.835C824.897 358.021 824.086 354.599 824.086 350.577C824.086 344.667 825.815 339.966 829.272 336.446C832.73 332.936 837.493 331.181 843.561 331.181C850.856 331.181 856.051 333.606 859.129 338.458C862.217 343.318 863.76 350.762 863.76 360.8C863.76 367.715 863.187 373.378 862.031 377.779C860.876 382.181 858.874 385.506 856.033 387.755C853.193 390.013 849.251 391.142 844.205 391.142C838.463 391.142 834.035 389.634 830.922 386.635C827.808 383.627 826.071 379.843 825.691 375.283H834.141ZM844.364 361.602C847.584 361.602 850.115 360.597 851.967 358.586C853.819 356.566 854.746 353.902 854.746 350.577C854.746 346.872 853.767 343.988 851.808 341.924C849.85 339.851 847.178 338.819 843.799 338.819C840.421 338.819 837.749 339.878 835.791 342.003C833.833 344.12 832.853 346.899 832.853 350.33C832.853 353.602 833.806 356.301 835.711 358.418C837.616 360.544 840.501 361.602 844.364 361.602Z" fill="#653090"/>
          <path d="M883.721 360.563C878.138 357.776 875.351 353.374 875.351 347.367C875.351 344.466 876.074 341.811 877.521 339.394C878.976 336.986 881.146 335.063 884.048 333.643C886.941 332.223 890.487 331.508 894.668 331.508C898.796 331.508 902.324 332.223 905.252 333.643C908.172 335.063 910.359 336.986 911.814 339.394C913.261 341.811 913.984 344.466 913.984 347.367C913.984 350.366 913.217 353.013 911.691 355.288C910.165 357.573 908.136 359.328 905.614 360.563C908.674 361.692 911.082 363.526 912.855 366.076C914.628 368.625 915.51 371.615 915.51 375.055C915.51 378.539 914.628 381.626 912.855 384.307C911.082 386.989 908.621 389.053 905.455 390.508C902.288 391.955 898.69 392.678 894.668 392.678C890.645 392.678 887.064 391.955 883.924 390.508C880.784 389.053 878.332 386.989 876.559 384.307C874.786 381.626 873.904 378.539 873.904 375.055C873.904 371.562 874.786 368.554 876.559 366.04C878.332 363.518 880.714 361.692 883.721 360.563ZM905.208 348.488C905.208 345.489 904.273 343.178 902.394 341.572C900.516 339.958 897.94 339.156 894.668 339.156C891.448 339.156 888.899 339.958 887.02 341.572C885.141 343.178 884.206 345.515 884.206 348.576C884.206 351.257 885.186 353.445 887.144 355.129C889.102 356.823 891.607 357.67 894.668 357.67C897.728 357.67 900.251 356.805 902.235 355.094C904.22 353.374 905.208 351.178 905.208 348.488ZM894.668 364.585C891.131 364.585 888.246 365.458 886.015 367.205C883.792 368.951 882.672 371.456 882.672 374.728C882.672 377.789 883.748 380.268 885.891 382.173C888.044 384.078 890.963 385.031 894.668 385.031C898.319 385.031 901.204 384.069 903.32 382.138C905.437 380.206 906.496 377.736 906.496 374.728C906.496 371.509 905.411 369.013 903.241 367.249C901.062 365.476 898.213 364.585 894.668 364.585Z" fill="#653090"/>
          <path d="M962.355 339.634L939.977 391.224H930.725L953.34 340.595H923.562V332.789H962.355V339.634Z" fill="#653090"/>
          <path d="M477.732 151.318C474.11 150.098 470.168 148.927 465.858 147.648C463.834 147.056 461.697 146.476 459.512 145.938L449.875 166.667L415.887 239.594H455.15L481.888 180.3L482.367 179.181L492.383 156.806L477.732 151.318ZM569.746 91.2779L527.385 0.183594L473.205 116.483L460.957 142.795C462.927 142.48 464.84 142.155 466.652 141.841C471.073 141.089 474.963 140.344 478.371 139.545L502.824 133.424C502.824 133.424 508.258 120.152 513.965 105.827C520.299 90.1118 527.012 73.1117 527.71 70.5008C528.455 73.223 533.783 86.808 538.732 99.3752C543.532 111.31 547.954 122.342 547.954 122.342L580.614 114.511L569.746 91.2779Z" fill="#653090"/>
          <path d="M638.734 239.544L591.311 221.27L577.131 188.56L559.985 149.138L547.947 122.34L580.609 114.508L623.224 206.136L638.734 239.544Z" fill="#653090"/>
          <path d="M577.135 188.561L559.81 181.955L529.236 170.608L492.37 156.809L477.719 151.322C474.097 150.097 470.155 148.929 465.84 147.646C463.814 147.06 461.684 146.48 459.499 145.941C457.107 145.302 454.598 144.722 451.988 144.077C455.137 143.65 458.115 143.224 460.938 142.799C462.914 142.484 464.827 142.159 466.639 141.84C471.06 141.093 474.95 140.348 478.358 139.548L502.811 133.421L531.74 126.229L547.941 122.341L580.587 114.515L569.719 91.2817L538.719 99.379L537.76 99.6514L515.385 105.452L513.952 105.831L473.192 116.485L381.719 140.348L449.857 166.67L482.354 179.185L591.301 221.277L577.135 188.561Z" fill="#653090"/>
          <path d="M0.833496 10.0332V41.8381H62.0997V240.339H99.5036V41.8381H160.338V10.0332H0.833496Z" fill="#653090"/>
          <path d="M339.834 10.0332V95.0056H226.573V10.0332H189.066V240.339H226.573V128.515H339.834V240.339H377.179V10.0332H339.834Z" fill="#653090"/>
          <path d="M680.835 108.222V42.1663H687.548C706.471 42.1663 719.41 44.7183 726.378 49.9452C733.359 55.1069 736.773 64.057 736.773 76.7367C736.773 88.5151 733.526 96.661 727.082 101.296C720.522 105.883 707.417 108.222 687.548 108.222H680.835ZM796.01 232.884L722.389 135.552C739.765 133.474 752.871 127.241 761.808 116.799C770.707 106.358 775.182 91.92 775.182 73.4393C775.182 62.9382 773.277 53.2964 769.377 44.5061C765.49 35.6621 760.005 28.5237 752.82 22.9299C746.849 18.3474 739.701 15.05 731.237 13.0208C722.76 11.0544 710.882 10.0366 695.603 10.0366H643.334V240.347H678.495V137.31H682.753L759.366 240.347H801.662L796.01 232.884Z" fill="#653090"/>
          <path d="M872.336 149.888L895.951 97.3587C897.434 93.6827 898.828 89.6871 900.26 85.3195C901.641 80.9505 902.971 76.2619 904.249 71.255C905.796 76.5816 907.177 81.43 908.724 85.7989C910.169 90.1666 911.55 94.0024 912.931 97.3587L936.483 149.888H872.336ZM903.942 0.932617L795.94 232.889L792.424 240.348H831.804L858.54 181.054H949.742L975.863 240.348H1015.38L903.942 0.932617Z" fill="#653090"/>
          </svg>
          </> );
        break;
      case 'case14':
        svgComponent =(<>
        <svg  width="101.383" height="46" viewBox="0 0 1016 426" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1015.38 296.486H514.507L457.139 425.256H1015.38V296.486ZM1000.05 311.816V409.926H480.751L524.456 311.816H1000.05Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M527.547 296.486H0.86193V425.256H471.661L527.547 296.486Z" fill="white"/>
<path d="M139.047 346.369C137.186 342.78 134.602 340.072 131.294 338.246C127.977 336.411 124.15 335.503 119.801 335.503C115.047 335.503 110.804 336.57 107.073 338.705C103.351 340.848 100.431 343.882 98.3321 347.816C96.224 351.75 95.1744 356.301 95.1744 361.47C95.1744 366.648 96.224 371.217 98.3321 375.186C100.431 379.155 103.351 382.207 107.073 384.341C110.804 386.485 115.047 387.552 119.801 387.552C126.222 387.552 131.426 385.753 135.431 382.172C139.426 378.582 141.878 373.722 142.778 367.583H115.77V358.162H155.4V367.371C154.642 372.963 152.657 378.097 149.446 382.789C146.245 387.482 142.073 391.221 136.93 394.017C131.788 396.814 126.081 398.207 119.801 398.207C113.045 398.207 106.87 396.637 101.278 393.497C95.6948 390.366 91.2581 386 87.9857 380.408C84.7045 374.824 83.064 368.509 83.064 361.47C83.064 354.44 84.7045 348.125 87.9857 342.533C91.2581 336.949 95.7124 332.583 101.331 329.443C106.958 326.303 113.115 324.742 119.801 324.742C127.457 324.742 134.275 326.62 140.238 330.378C146.209 334.135 150.54 339.463 153.23 346.369H139.047Z" fill="#653090"/>
<path d="M204.345 397.586L187.789 368.814H178.783V397.586H166.99V325.664H191.828C197.341 325.664 202.016 326.634 205.844 328.566C209.672 330.498 212.539 333.082 214.435 336.328C216.332 339.565 217.284 343.19 217.284 347.186C217.284 351.878 215.917 356.139 213.191 359.967C210.466 363.795 206.276 366.406 200.622 367.782L218.422 397.586H204.345ZM178.783 359.402H191.828C196.239 359.402 199.573 358.3 201.813 356.086C204.054 353.881 205.174 350.917 205.174 347.186C205.174 343.464 204.071 340.553 201.866 338.445C199.652 336.337 196.309 335.287 191.828 335.287H178.783V359.402Z" fill="#653090"/>
<path d="M265.198 398.304C258.503 398.304 252.347 396.734 246.719 393.594C241.101 390.454 236.655 386.079 233.374 380.452C230.101 374.833 228.461 368.5 228.461 361.461C228.461 354.431 230.101 348.116 233.374 342.524C236.655 336.94 241.101 332.574 246.719 329.434C252.347 326.294 258.503 324.733 265.198 324.733C271.954 324.733 278.146 326.294 283.774 329.434C289.392 332.574 293.82 336.94 297.066 342.524C300.312 348.116 301.926 354.431 301.926 361.461C301.926 368.5 300.312 374.833 297.066 380.452C293.82 386.079 289.392 390.454 283.774 393.594C278.146 396.734 271.954 398.304 265.198 398.304ZM265.198 388.055C269.952 388.055 274.195 386.97 277.926 384.8C281.648 382.63 284.559 379.526 286.667 375.486C288.775 371.455 289.825 366.78 289.825 361.461C289.825 356.151 288.775 351.494 286.667 347.499C284.559 343.494 281.648 340.424 277.926 338.281C274.195 336.147 269.952 335.079 265.198 335.079C260.435 335.079 256.192 336.147 252.47 338.281C248.739 340.424 245.828 343.494 243.72 347.499C241.621 351.494 240.563 356.151 240.563 361.461C240.563 366.78 241.621 371.455 243.72 375.486C245.828 379.526 248.739 382.63 252.47 384.8C256.192 386.97 260.435 388.055 265.198 388.055Z" fill="#653090"/>
<path d="M325.211 325.66V371.499C325.211 376.951 326.64 381.061 329.507 383.813C332.373 386.574 336.351 387.958 341.458 387.958C346.636 387.958 350.649 386.574 353.516 383.813C356.373 381.061 357.811 376.951 357.811 371.499V325.66H369.604V371.297C369.604 377.162 368.325 382.128 365.776 386.194C363.227 390.269 359.813 393.304 355.535 395.306C351.258 397.308 346.53 398.305 341.352 398.305C336.184 398.305 331.473 397.308 327.231 395.306C322.988 393.304 319.628 390.269 317.14 386.194C314.653 382.128 313.418 377.162 313.418 371.297V325.66H325.211Z" fill="#653090"/>
<path d="M435.21 347.089C435.21 350.741 434.346 354.189 432.626 357.435C430.897 360.672 428.136 363.319 424.343 365.347C420.55 367.385 415.69 368.399 409.754 368.399H396.718V397.586H384.916V325.664H409.754C415.276 325.664 419.951 326.617 423.779 328.513C427.607 330.409 430.465 332.976 432.37 336.222C434.266 339.459 435.21 343.084 435.21 347.089ZM409.754 358.776C414.235 358.776 417.587 357.762 419.792 355.724C421.997 353.695 423.108 350.811 423.108 347.089C423.108 339.221 418.654 335.287 409.754 335.287H396.718V358.776H409.754Z" fill="#653090"/>
<path d="M573.207 391.973C569.458 391.973 566.071 391.311 563.072 389.997C560.065 388.683 557.701 386.822 555.99 384.405C554.27 381.988 553.414 379.175 553.414 375.955H563.231C563.443 378.363 564.395 380.348 566.089 381.909C567.783 383.461 570.155 384.246 573.207 384.246C576.374 384.246 578.843 383.479 580.616 381.953C582.389 380.418 583.271 378.451 583.271 376.035C583.271 374.156 582.724 372.621 581.622 371.448C580.519 370.266 579.152 369.349 577.52 368.705C575.88 368.061 573.613 367.364 570.72 366.614C567.068 365.653 564.104 364.674 561.82 363.677C559.544 362.689 557.595 361.146 555.99 359.055C554.375 356.956 553.573 354.169 553.573 350.685C553.573 347.465 554.375 344.643 555.99 342.226C557.595 339.818 559.853 337.966 562.746 336.678C565.648 335.39 569 334.746 572.81 334.746C578.226 334.746 582.671 336.096 586.129 338.812C589.587 341.52 591.509 345.234 591.88 349.953H581.745C581.578 347.915 580.616 346.177 578.843 344.722C577.07 343.275 574.742 342.552 571.84 342.552C569.211 342.552 567.068 343.223 565.401 344.563C563.743 345.904 562.905 347.836 562.905 350.358C562.905 352.078 563.434 353.481 564.475 354.583C565.524 355.686 566.856 356.559 568.462 357.203C570.076 357.847 572.272 358.544 575.059 359.293C578.764 360.308 581.78 361.331 584.118 362.354C586.446 363.368 588.44 364.938 590.072 367.055C591.712 369.181 592.524 372.012 592.524 375.549C592.524 378.39 591.765 381.08 590.23 383.602C588.705 386.116 586.473 388.145 583.553 389.68C580.634 391.206 577.185 391.973 573.207 391.973Z" fill="white"/>
<path d="M608.065 341.194C606.398 341.194 605.004 340.63 603.875 339.501C602.746 338.372 602.182 336.978 602.182 335.32C602.182 333.653 602.746 332.259 603.875 331.13C605.004 330.001 606.398 329.445 608.065 329.445C609.67 329.445 611.037 330.001 612.166 331.13C613.295 332.259 613.86 333.653 613.86 335.32C613.86 336.978 613.295 338.372 612.166 339.501C611.037 340.63 609.67 341.194 608.065 341.194ZM612.572 347.069V391.418H603.39V347.069H612.572Z" fill="white"/>
<path d="M647.016 346.336C650.5 346.336 653.631 347.059 656.392 348.506C659.153 349.952 661.314 352.104 662.875 354.945C664.428 357.794 665.204 361.225 665.204 365.247V391.408H656.11V366.614C656.11 362.645 655.122 359.602 653.129 357.485C651.144 355.359 648.436 354.301 645.005 354.301C641.565 354.301 638.848 355.359 636.837 357.485C634.826 359.602 633.812 362.645 633.812 366.614V391.408H624.639V347.059H633.812V352.131C635.32 350.305 637.234 348.885 639.572 347.862C641.909 346.848 644.388 346.336 647.016 346.336Z" fill="white"/>
<path d="M673.812 369.186C673.812 364.626 674.739 360.612 676.591 357.155C678.443 353.697 681.01 351.025 684.282 349.146C687.555 347.267 691.312 346.332 695.546 346.332C700.918 346.332 705.354 347.602 708.865 350.151C712.384 352.7 714.757 356.334 715.992 361.062H706.095C705.284 358.857 703.996 357.146 702.232 355.911C700.459 354.676 698.227 354.059 695.546 354.059C691.789 354.059 688.798 355.382 686.576 358.037C684.344 360.701 683.233 364.414 683.233 369.186C683.233 373.967 684.344 377.689 686.576 380.379C688.798 383.06 691.789 384.401 695.546 384.401C700.856 384.401 704.375 382.064 706.095 377.398H715.992C714.704 381.905 712.287 385.486 708.75 388.141C705.204 390.796 700.803 392.128 695.546 392.128C691.312 392.128 687.555 391.175 684.282 389.27C681.01 387.365 678.443 384.683 676.591 381.217C674.739 377.759 673.812 373.746 673.812 369.186Z" fill="white"/>
<path d="M765.809 368.145C765.809 369.803 765.703 371.303 765.491 372.652H731.603C731.868 376.189 733.191 379.029 735.546 381.182C737.91 383.325 740.803 384.401 744.243 384.401C749.173 384.401 752.666 382.337 754.704 378.2H764.6C763.26 382.275 760.834 385.618 757.323 388.22C753.804 390.822 749.447 392.128 744.243 392.128C740 392.128 736.207 391.175 732.856 389.27C729.495 387.365 726.867 384.683 724.961 381.217C723.056 377.759 722.104 373.746 722.104 369.186C722.104 364.626 723.03 360.612 724.882 357.155C726.734 353.697 729.336 351.025 732.688 349.146C736.049 347.267 739.894 346.332 744.243 346.332C748.424 346.332 752.155 347.241 755.427 349.066C758.699 350.892 761.248 353.45 763.074 356.749C764.9 360.057 765.809 363.849 765.809 368.145ZM756.23 365.243C756.177 361.865 754.968 359.157 752.613 357.119C750.249 355.073 747.321 354.059 743.837 354.059C740.671 354.059 737.963 355.064 735.705 357.075C733.455 359.086 732.115 361.812 731.682 365.243H756.23Z" fill="white"/>
<path d="M793.338 341.108V332.737H810.079V391.411H800.818V341.108H793.338Z" fill="white"/>
<path d="M834.141 375.466C834.679 378.148 835.835 380.185 837.608 381.579C839.372 382.981 841.762 383.678 844.77 383.678C848.571 383.678 851.367 382.17 853.14 379.171C854.904 376.163 855.795 371.039 855.795 363.797C854.508 365.623 852.691 367.025 850.362 368.022C848.025 369.01 845.493 369.513 842.759 369.513C839.266 369.513 836.099 368.798 833.259 367.378C830.41 365.958 828.17 363.832 826.538 361.019C824.897 358.205 824.086 354.783 824.086 350.76C824.086 344.851 825.815 340.149 829.272 336.63C832.73 333.119 837.493 331.364 843.561 331.364C850.856 331.364 856.051 333.79 859.129 338.641C862.217 343.501 863.76 350.946 863.76 360.983C863.76 367.898 863.187 373.561 862.031 377.963C860.876 382.364 858.874 385.689 856.033 387.938C853.193 390.197 849.251 391.326 844.205 391.326C838.463 391.326 834.035 389.817 830.922 386.818C827.808 383.81 826.071 380.027 825.691 375.466H834.141ZM844.364 361.786C847.584 361.786 850.115 360.78 851.967 358.769C853.819 356.749 854.746 354.086 854.746 350.76C854.746 347.056 853.767 344.171 851.808 342.107C849.85 340.035 847.178 339.003 843.799 339.003C840.421 339.003 837.749 340.061 835.791 342.187C833.833 344.304 832.853 347.082 832.853 350.513C832.853 353.786 833.806 356.485 835.711 358.602C837.616 360.728 840.501 361.786 844.364 361.786Z" fill="white"/>
<path d="M883.721 360.746C878.138 357.959 875.351 353.558 875.351 347.551C875.351 344.649 876.074 341.994 877.521 339.577C878.976 337.169 881.146 335.246 884.048 333.826C886.941 332.406 890.487 331.692 894.668 331.692C898.796 331.692 902.324 332.406 905.252 333.826C908.172 335.246 910.359 337.169 911.814 339.577C913.261 341.994 913.984 344.649 913.984 347.551C913.984 350.55 913.217 353.196 911.691 355.472C910.165 357.756 908.136 359.512 905.614 360.746C908.674 361.875 911.082 363.71 912.855 366.259C914.628 368.808 915.51 371.798 915.51 375.238C915.51 378.722 914.628 381.81 912.855 384.491C911.082 387.172 908.621 389.236 905.455 390.692C902.288 392.138 898.69 392.862 894.668 392.862C890.645 392.862 887.064 392.138 883.924 390.692C880.784 389.236 878.332 387.172 876.559 384.491C874.786 381.81 873.904 378.722 873.904 375.238C873.904 371.746 874.786 368.738 876.559 366.224C878.332 363.701 880.714 361.875 883.721 360.746ZM905.208 348.671C905.208 345.672 904.273 343.361 902.394 341.756C900.516 340.142 897.94 339.339 894.668 339.339C891.448 339.339 888.899 340.142 887.02 341.756C885.141 343.361 884.206 345.699 884.206 348.759C884.206 351.441 885.186 353.628 887.144 355.313C889.102 357.006 891.607 357.853 894.668 357.853C897.728 357.853 900.251 356.989 902.235 355.278C904.22 353.558 905.208 351.361 905.208 348.671ZM894.668 364.769C891.131 364.769 888.246 365.642 886.015 367.388C883.792 369.135 882.672 371.64 882.672 374.912C882.672 377.973 883.748 380.451 885.891 382.356C888.044 384.262 890.963 385.214 894.668 385.214C898.319 385.214 901.204 384.253 903.32 382.321C905.437 380.389 906.496 377.92 906.496 374.912C906.496 371.693 905.411 369.196 903.241 367.432C901.062 365.659 898.213 364.769 894.668 364.769Z" fill="white"/>
<path d="M962.355 339.817L939.977 391.408H930.725L953.34 340.779H923.562V332.973H962.355V339.817Z" fill="white"/>
<path d="M477.732 151.501C474.11 150.282 470.168 149.11 465.858 147.832C463.834 147.24 461.697 146.659 459.512 146.121L449.875 166.851L415.887 239.778H455.15L481.888 180.483L482.367 179.364L492.383 156.989L477.732 151.501ZM569.746 91.4615L527.385 0.367188L473.205 116.666L460.957 142.978C462.927 142.664 464.84 142.339 466.652 142.025C471.073 141.273 474.963 140.527 478.371 139.728L502.824 133.608C502.824 133.608 508.258 120.336 513.965 106.011C520.299 90.2954 527.012 73.2953 527.71 70.6844C528.455 73.4066 533.783 86.9916 538.732 99.5588C543.532 111.493 547.954 122.526 547.954 122.526L580.614 114.695L569.746 91.4615Z" fill="white"/>
<path d="M638.734 239.728L591.311 221.454L577.131 188.744L559.985 149.321L547.947 122.523L580.609 114.692L623.224 206.319L638.734 239.728Z" fill="white"/>
<path d="M577.135 188.744L559.81 182.139L529.236 170.792L492.37 156.993L477.719 151.505C474.097 150.28 470.155 149.113 465.84 147.829C463.814 147.244 461.684 146.663 459.499 146.125C457.107 145.486 454.598 144.905 451.988 144.261C455.137 143.834 458.115 143.408 460.938 142.982C462.914 142.668 464.827 142.343 466.639 142.023C471.06 141.277 474.95 140.531 478.358 139.732L502.811 133.605L531.74 126.413L547.941 122.525L580.587 114.699L569.719 91.4653L538.719 99.5626L537.76 99.835L515.385 105.636L513.952 106.014L473.192 116.669L381.719 140.531L449.857 166.853L482.354 179.368L591.301 221.461L577.135 188.744Z" fill="white"/>
<path d="M0.833496 10.2168V42.0217H62.0997V240.523H99.5036V42.0217H160.338V10.2168H0.833496Z" fill="white"/>
<path d="M339.834 10.2168V95.1892H226.573V10.2168H189.066V240.523H226.573V128.698H339.834V240.523H377.179V10.2168H339.834Z" fill="white"/>
<path d="M680.835 108.406V42.3499H687.548C706.471 42.3499 719.41 44.9019 726.378 50.1288C733.359 55.2905 736.773 64.2406 736.773 76.9203C736.773 88.6987 733.526 96.8446 727.082 101.479C720.522 106.067 707.417 108.406 687.548 108.406H680.835ZM796.01 233.068L722.389 135.735C739.765 133.658 752.871 127.425 761.808 116.982C770.707 106.541 775.182 92.1036 775.182 73.6229C775.182 63.1218 773.277 53.48 769.377 44.6897C765.49 35.8457 760.005 28.7073 752.82 23.1135C746.849 18.531 739.701 15.2336 731.237 13.2044C722.76 11.238 710.882 10.2202 695.603 10.2202H643.334V240.531H678.495V137.493H682.753L759.366 240.531H801.662L796.01 233.068Z" fill="white"/>
<path d="M872.336 150.072L895.951 97.5422C897.434 93.8663 898.828 89.8707 900.26 85.5031C901.641 81.1341 902.971 76.4455 904.249 71.4386C905.796 76.7652 907.177 81.6136 908.724 85.9825C910.169 90.3502 911.55 94.1859 912.931 97.5422L936.483 150.072H872.336ZM903.942 1.11621L795.94 233.073L792.424 240.532H831.804L858.54 181.237H949.742L975.863 240.532H1015.38L903.942 1.11621Z" fill="white"/>
</svg>

</> );
        break;
      case 'case15':
        svgComponent =(<></> );
        break;
    default:
      // Default case when no condition is met
      svgComponent = null;
  }

  return <div>{svgComponent}</div>;
};

export default Allsvgcomp;
